const navigationList = [
  {
    title: 'Calculator',
    permission: 'menuCalculator',
    children: [
      { path: '/oilwax_calculator', title: 'Oil & Wax Mass', permission: 'pageOilwaxCalculator' },
      { path: '/production_cost', title: 'Production Cost', permission: 'pageProductionCalculator' },
    ],
  },
  {
    title: 'Colors',
    permission: 'menuColors',
    children: [
      { path: '/design', title: 'Design', permission: 'pageDesign' },
      { path: '/designs', title: 'List', permission: 'pageDesignList' },
      { path: '/colors', title: 'Colors', permission: 'pageColors' },
      { path: '/pantone', title: 'Pantone', permission: 'pagePantone' },
      { path: '/tint_history', title: 'Tint History', permission: 'pageTintHistory' },
    ],
  },
  {
    title: 'Candles',
    permission: 'menuCandles',
    children: [
      { path: '/candles', title: 'Recipes', permission: 'pageRecipes' },
      { path: '/wick_configurator', title: 'Wick Configurator', permission: 'pageWickConfigurator' },
      { path: '/wick_test', title: 'Wick Testing', permission: 'pageWickTesting' },
      { path: '/burn_testing', title: 'Burn Test', permission: 'pageBurnTest' },
      { path: '/lifetime_test', title: 'Burning Time', permission: 'pageBurningTime' },
      {
        path: '/path', title: 'Weight Loss', disabled: true, permission: 'pageUnused',
      },
      { path: '/soot_test', title: 'Soot Index', permission: 'pageSootIndex' },
      {
        path: '/path', title: 'Heat Release', disabled: true, permission: 'pageUnused',
      },
      {
        path: '/path', title: 'CO Production', disabled: true, permission: 'pageUnused',
      },
    ],
  },
  {
    title: 'Labels',
    permission: 'menuLabels',
    children: [
      { path: '/labels', title: 'Labels', permission: 'pageLabels' },
      {
        path: '/path', title: 'Templates', disabled: true, permission: 'pageUnused',
      },
      {
        path: '/path', title: 'Price List', disabled: true, permission: 'pageUnused',
      },
    ],
  },
  {
    title: 'PCN',
    permission: 'menuPcn',
    children: [
      {
        path: '/path', title: 'SDS', disabled: true, permission: 'pageUnused',
      },
      {
        path: '/path', title: 'CLP Label Design Tool', disabled: true, permission: 'pageUnused',
      },
      { path: '/pcn_shortcuts', title: 'Shortcut', permission: 'pagePcnShortcut' },
    ],
  },
  {
    title: 'Recipes',
    permission: 'menuRecipes',
    children: [
      {
        path: '/path', title: 'Candle Science', disabled: true, permission: 'pageUnused',
      },
      {
        path: '/path', title: 'Candle Shack', disabled: true, permission: 'pageUnused',
      },
      { path: '/candles', title: 'Candlely', permission: 'pageRecipes' },
    ],
  },
  {
    title: 'Documents',
    permission: 'menuDocuments',
    children: [
      { path: '/invoices', title: 'Invoices', permission: 'pageInvoices' },
      { path: '/files', title: 'Documents', permission: 'pageDocuments' },
      { path: '/notes', title: 'Notes', permission: 'pageNotes' },
      { path: '/picture_upload', title: 'Picture Upload', permission: 'pagePictureUpload' },
    ],
  },
];

export default navigationList;
